<template>
  <div>
    <v-container fluid class="pa-12 ">
      <v-card>
        <v-card-text>
          <v-tabs v-model="currentItem">
            <v-tab>{{ $t('Emails') }}</v-tab>
            <!-- <v-tab>{{ $t('SMS') }}</v-tab>
            <v-tab>{{ $t('MMS') }}</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="currentItem">
            <v-tab-item class="pa-6 pb-0">
                <v-row class="pb-6">
                  <v-col cols="12">
                    <v-row class="pa-4 pb-0">
                        <v-col cols="10">
                          <v-row>
                            <template v-for="(filter, index) in filtersMarketing">
                                <vDaterangePicker v-if="filter.type == 'dateperiod'" mode="past" v-model="filter.value" @change="reloadData" class="mr-6" :label="index" :key="'filter_'+index"/>
                                <v-select v-else-if="filter.type == 'select'" :no-data-text="$t('noData')" multiple v-model="filter.value" class="mr-6 mt-0 pt-0"  @change="reloadData" :items="filter.items" style="max-width:200px;" :label="$t(index)" :key="'filter_'+index"/>
                            </template>
                          </v-row>
                        </v-col>
                        <v-col cols="2" class="pt-0 text-right">
                          <v-btn color="primary" small @click="launchExport" :loading="loading">{{ $t('exportStat') }}</v-btn>
                        </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-for="(stat, index) in stats" :key="'stats_'+statKey+'_'+index" class="pt-6">
                      <v-card>
                        <v-card-title>
                          <h5 class="primary--text">{{ $t(index) }}</h5>
                        </v-card-title>
                        <v-card-text>
                          <div class="d-flex justify-space-around px-12 mx-6 pb-6">
                            <div v-for="(substat, index) in stat" class="d-flex flex-column align-center justify-center">
                              <v-icon large color="primary">
                                {{ substat.icon }}
                              </v-icon>
                              <h2 class="mb-0">{{ substat.value }}</h2>
                              <p class="mt-0 mb-1">{{ $t(substat.label) }}</p>
                              <v-chip outlined small :color="substat.color">{{ substat.percent }}</v-chip>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                  </v-col>
                  <v-col cols="12">
                    <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" :key="currentKey" ref="datatableOpe" />
                  </v-col>
                </v-row>
            </v-tab-item>
            <!-- <v-tab-item>SMS</v-tab-item>
            <v-tab-item>MMS</v-tab-item> -->
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <v-dialog persistent width="400" v-model="showDownload">
        <v-card>
            <v-card-text class="pa-6">
                <h5 class="icon-title">
                {{$t('downloadReady')}}
                <v-btn @click="showDownload = false" text class="square">
                    <v-icon small>$close</v-icon>
                </v-btn>
                </h5>
                <div class="text-center mt-4 mb-2">
                    <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    </v-container>
  </div>
</template>
<script>
import vDaterangePicker from '@/components/forms/inputs/v-daterange-picker.vue'
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "StatisticsList",
  components: {
    vDaterangePicker, DatatableV2
  },
  data() {
    return {
      currentItem:0,
      parents: [],
      filtersMarketing: {},
      stats: {},
      currentKey: 0,
      statKey: 0,
      apiUrl: "/stats/list?canal=EMAIL",
      loading: false,
      downloadUrl: '',
      api: process.env.VUE_APP_API_URL,
      showDownload: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    launchExport(){
        this.loading = true;
        GenericDataService.postData('/stats/exportStatsByCanal', {stats : this.stats, filters : this.filtersMarketing}).then((response)=> {
            this.loading = false
            if(response.data.data.directDownload){
                this.downloadUrl = this.api + response.data.data.directDownload
                this.showDownload = true;
            }
        }).catch((error) => {
            this.loading = false
        });
    },
    reloadData(){
        GenericDataService.postData('/stats/getStatsByCanal?canal=EMAIL', {filters : this.filtersMarketing}).then((response)=> {
            this.stats = response.data.data.stats
            this.statKey++
        })
    },
    initData() {
        GenericDataService.getData("/stats/getStatsByCanal?canal=EMAIL").then((response) => {
            this.filtersMarketing = response.data.data.filters
            for (const key in this.filtersMarketing) {
                if (Object.prototype.hasOwnProperty.call(this.filtersMarketing, key)) {
                    const element = this.filtersMarketing[key];
                    if(element.type == 'select' && element.hasOwnProperty('selectDataUrl')){
                        GenericDataService.getData(element.selectDataUrl).then((response) => {
                            element.items = response.data.data
                        })
                    }
                }
            }
            this.stats = response.data.data.stats
        });
      }
  },
  computed: {
    
  },
  mounted() {
    
  },
};
</script>
