<template>
  <div>
    <v-container fluid class="pa-6 ">
        <DatatableV2 :displayTitle="false"  @openLPoffline="openLPoffline" :flat="false" switchUrl="/landingpagev2/switchActive" switchValue="active" switchIds="id"  @openEditLPForm="openEditLPForm" @openLibraryDialog="openLibraryDialog" @openChangeLibraryDialog="openChangeLibraryDialog" :url="apiUrl" ref="landing2Datatable"/>
        <LibraryDialogComponent @customRefresh="customRefresh" ref="addLP" v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        <LibraryDialogComponent ref="changeLP" v-if="changeFormLib" :form="changeFormLib" :libType="libType" :libParams="libParams" @selectModel="selectModel" @cancel="changeFormLib = null;" :showLibraryDialog="showChangeLibraryDialog" @hideLibraryDialog="showChangeLibraryDialog = false;" />
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </v-container>
  </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import DatatableV2 from '@/components/DatatableV2'
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import getForm from "@/mixins/mixins";

export default {
    name: "landingPageList",
    components: {DatatableV2, LibraryDialogComponent, FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent")},
    mixins:[getForm],
    data() {
        return {
            apiUrl:"/landingpagev2/list",
            formLib: null,
            libType: "LANDINGPAGEV2",
            showLibraryDialog: false,
            showChangeLibraryDialog: false,
            changeFormLib: null,
            libParams: {},
            form: null
        };
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        openLPoffline(landing){
            let url = "https://preview."+landing['config.domain']+"?uniqId="+landing.uniqId
            window.open(url, '_blank');
        },
        customRefresh(){
            this.$refs.landing2Datatable.readDataFromAPI();
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        openChangeLibraryDialog(id){
            this.libParams = {id: id, mode:"change"};
            GenericDataService.getData("/library/getFilters?libType=" + this.libType + "&params[id]="+id).then((response) => {
                this.changeFormLib = response.data.data;
                this.showChangeLibraryDialog = true;
            });
            //console.log("change", id)
        },
        selectModel(obj) {
            console.log("model", obj)
            let payload = {id: obj.id, new_model_id: obj.model.id};
            GenericDataService.postData("/landingpagev2/changeModel", payload).then((response) => {
                this.$refs.landing2Datatable.readDataFromAPI();
            });
        },
        openEditLPForm(id){
            if(id.hasOwnProperty('id')){
                this.getForm('/library/getForm?formName=' + this.libType + '&addClass=conception&id=' + id.id + '&step=["conception"]');
            } else {
                this.getForm('/library/getForm?formName=' + this.libType + '&addClass=conception&id=' + id + '&step=["conception"]');
            }
        }
    },
};
</script>
<style lang="scss">
    .card-container {
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
        .text {
            font-size:1.1em;
        }
        .card-landing {
            width:23%;
            //height:300px;
        }
        .miniature-container {
            position:relative;
            width: 100%;
            height: 100%;
            max-height:150px;
            overflow:hidden;
            img {
                width:100%;
            }
        }
    }
    
</style>
